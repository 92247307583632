import React from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import Survey from './Survey/Survey.jsx'
import Thanks from './Survey/Thanks.jsx'
import { ToastContainer } from 'react-toastify'
import { toast } from 'react-toastify'

class Router extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      reasonOptions: [],
      timesOptions: [],
      areaOptions: []
    }
  }

  componentDidMount() {
    const body = { headers: { 'Content-Type': 'application/json' } }

    fetch(`/api/getReasonOptions`, body).then(res => res.json()).then(result => {
      this.setState({ reasonOptions: result })
    }).catch(error => {
      console.error(error)
      toast.error('There was a problem gathering data.')
    })

    fetch(`/api/getTimesOptions`, body).then(res => res.json()).then(result => {
      this.setState({ timesOptions: result })
    }).catch(error => {
      console.error(error)
      toast.error('There was a problem gathering data.')
    })

    fetch(`/api/getAreaOptions`, body).then(res => res.json()).then(result => {
      this.setState({ areaOptions: result })
    }).catch(error => {
      console.error(error)
      toast.error('There was a problem gathering data.')
    })
  }

  render() {
    return (
      <React.Fragment>
        <BrowserRouter>
          <Switch>
            <Route path='/survey'>
              <Survey reasonOptions={this.state.reasonOptions} timesOptions={this.state.timesOptions} areaOptions={this.state.areaOptions} />
            </Route>
            <Route path='/thanks'>
              <Thanks changeLoading={this.changeLoading} />
            </Route>
            <Route path='/'>
              <Redirect from='/' to='/survey' />
            </Route>
          </Switch>
        </BrowserRouter>

        <ToastContainer
          theme='colored'
          position='bottom-right'
          autoClose={4000}
          draggable
          pauseOnHover
          closeOnClick
        />
      </React.Fragment>
    )
  }
}

export default Router