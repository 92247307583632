import React from 'react'
import Select from 'react-select'

const customStyles = {
  container: provided => ({
    ...provided,
    margin: '16px 0px'
  }),
  control: provided => ({
    ...provided,
    padding: '6px 5px',
    borderRadius: '18px',
    borderColor: '#212121',
    borderWidth: '2px',
    boxSizing: 'border-box',
    color: 'black'
  }),
  singleValue: provided => ({
    ...provided,
    backgroundColor: 'white',
    color: 'black'
  }),
  input: provided => ({
    ...provided,
    color: 'white'
  }),
  menu: provided => ({
    ...provided,
    // backgroundColor: '#282828',
    color: 'black',
    marginLeft: '10px'
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isFocused ? '#212121' : null,
    color: isFocused ? 'white' : 'black',
    cursor: 'pointer'
  })
}

class Dropdown extends React.Component {
  render() {
    return (
      <div className={this.props.col}>
        <h3>{this.props.label}</h3>
        <Select styles={customStyles} options={this.props.options} value={this.props.value} onChange={this.props.onChange} />
      </div>
    )
  }
}

export default Dropdown