import React from 'react'
import Date from '../Components/Date.jsx'
import Input from '../Components/Input.jsx'
import Dropdown from '../Components/Dropdown.jsx'
import RadioGroupList from '../Components/RadioGroupList.jsx'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import { states } from '../../utils/constants.js'
import { toast } from 'react-toastify'

const useStyles = makeStyles(theme => ({ root: { fontSize: 'xxx-large' } }))

const CircularIndeterminate = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <CircularProgress size={80} thickness={5} />
    </div>
  )
}

const loadingStyle = {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}

class Survey extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      dob: '',
      address: '',
      city: '',
      state: {},
      zip: '',
      reason: '',
      other_reason: '',
      times: '',
      area: ''
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleDropdownChange = this.handleDropdownChange.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleInputChange(e) { this.setState({ [e.target.name]: e.target.value }) }
  handleDropdownChange(e) { this.setState({ state: e }) }
  handleDateChange(e) { this.setState({ dob: e }) }

  handleSubmit(e) {
    e.preventDefault()

    for (let elem in this.state) {
      if (!elem.includes('other')) {
        if (this.state[elem] === '') {
          toast.warn('All questions are required.')
          return
        }
      }
    }

    const { other_reason, state, dob, ...obj } = this.state
    fetch(`/api/saveMemberSurvey?${new URLSearchParams({ state: state.value, dob: dob.toLocaleDateString(), ...obj }).toString()}`, { headers: { 'Content-Type': 'application/json' } }).then(res => res.json()).then(result => {
      window.location = '/thanks'
    }).catch(error => {
      console.error(error)
      toast.error('There was a problem saving the Survey.')
    })
  }

  render() {
    return (
      <React.Fragment>
        <div className='container' id='logo_container'>
          <img alt='PBZLogo' src={process.env.PUBLIC_URL + '/images/PBZCS_logo_2019.png'} className='logo' />
        </div>

        <div className='container-fluid'>
          <div className='col-sm-3'></div>

          <form className='col-sm-6' onSubmit={this.handleSubmit} method='post' noValidate>
            <div className='row box'>
              <Input col='col-sm-9' label='Name:' inputKey='name' handleInputChange={this.handleInputChange} />
              <Date col='col-sm-3' label='Date of Birth:' date={this.state.dob} handleDateChange={this.handleDateChange} />
              <Input col='col-sm-12' label='Address:' inputKey='address' handleInputChange={this.handleInputChange} />
              <Input col='col-sm-4' label='City:' inputKey='city' handleInputChange={this.handleInputChange} />
              <Dropdown col='col-sm-4' label='State:' options={states} value={this.state.state} onChange={this.handleDropdownChange} />
              <Input col='col-sm-4' label='Zip:' inputKey='zip' handleInputChange={this.handleInputChange} format='numbers' />
            </div>


            <div className='row box'>
              {this.props.reasonOptions[0] ?
                <React.Fragment>
                  <h3>What is your primary reason for membership?</h3>
                  <RadioGroupList options={this.props.reasonOptions} hasOther={true}
                    stateKey='reason' state={this.state.reason} handleInputChange={this.handleInputChange}
                    other_Key='other_reason' other={this.other_electronicGame}
                  />
                </React.Fragment>
                :
                <div className='row' style={loadingStyle}>
                  <CircularIndeterminate />
                </div>
              }
            </div>

            <div className='row box'>
              {this.props.timesOptions[0] ?
                <React.Fragment>
                  <h3>How many times do you anticipate visiting Palm Beach Zoo during your membership?</h3>
                  <RadioGroupList options={this.props.timesOptions} hasOther={false}
                    stateKey='times' state={this.state.times} handleInputChange={this.handleInputChange}
                  />
                </React.Fragment>
                :
                <div className='row' style={loadingStyle}>
                  <CircularIndeterminate />
                </div>
              }
            </div>

            <div className='row box'>
              {this.props.areaOptions[0] ?
                <React.Fragment>
                  <h3>What area of your membership are you most excited about?</h3>
                  <RadioGroupList options={this.props.areaOptions} hasOther={false}
                    stateKey='area' state={this.state.area} handleInputChange={this.handleInputChange}
                  />
                </React.Fragment>
                :
                <div className='row' style={loadingStyle}>
                  <CircularIndeterminate />
                </div>
              }
            </div>

            <input type='submit' className='btn btn-secondary' value='Submit' style={{ float: 'right' }} />
          </form>

          <div className='col-sm-3'></div>
        </div>

      </React.Fragment >
    )
  }
}

export default Survey