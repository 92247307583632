import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { RadioGroup, FormControlLabel, Radio } from '@material-ui/core'

const StyledRadio = withStyles({
  root: {
    transform: 'scale(1.5)',
    color: '#54632B',
    '&$checked': { color: '#54632B' }
  },
  checked: {}
})((props) => <Radio color='default' {...props} />)

const StyledFormControl = withStyles({
  label: {
    fontSize: '14px',
    fontFamily: "'Helvetica', sans-serif",
    fontWeight: 700
  }
})((props) => <FormControlLabel labelPlacement='end' {...props} />)

class RadioGroupList extends React.Component {
  constructor(props) {
    super(props)
    this.state = { value: '' }
    this.handleChange = this.handleChange.bind(this)
  }

  static getDerivedStateFromProps(prop, state) { if (prop.state !== 'Other:') { return { value: '' } } return {} }
  handleChange(e) { this.setState({ value: e.target.value }) }

  render() {
    return (
      <RadioGroup name={this.props.stateKey} value={this.props.state} onChange={this.props.handleInputChange}>
        {this.props.options.map(elem => <StyledFormControl key={Math.random()} value={elem} control={<StyledRadio />} label={elem} />)}

        {this.props.hasOther &&
          <React.Fragment>
            <div className='row col-lg-2'>
              <StyledFormControl key={Math.random()} value={'Other:'} control={<StyledRadio />} label={'Other:'} />
            </div>

            <div className='row col-lg-6'>
              <input name={this.props.other_Key} className='inputs' disabled={this.props.state !== 'Other:'} value={this.state.value} onChange={this.handleChange} onBlur={this.props.handleInputChange} />
            </div>
          </React.Fragment>
        }
      </RadioGroup>
    )
  }
}

export default RadioGroupList