import React from 'react'

class Thanks extends React.Component {
  render() {
    return (
      <div style={{ height: '100vh' }}>
        <div className='container' id='logo_container'>
          <img alt='PBZLogo' src={process.env.PUBLIC_URL + '/images/PBZCS_logo_2019.png'} className='logo' />
        </div>

        <div className='container-fluid'>
          <div className='col-sm-3'></div>

          <div className='col-sm-6'>
            <div className='row box' style={{ minHeight: '250px', textAlign: 'center', paddingTop: '80px' }}>
              <h1>Thank you for participating in our survey.</h1>
            </div>
          </div>

          <div className='col-sm-3'></div>
        </div>
      </div>
    )
  }
}

export default Thanks